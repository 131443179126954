import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Content from './content';
import Icon from './icon';

const Contact = ({ query = [] }) => {
  let properties = ['name', 'role', 'url', 'message', 'contacts'];
  if (query.length) {
    properties = query;
  }

  const { allDataYaml } = useStaticQuery(graphql`
    query ContactQuery {
      allDataYaml {
        nodes {
          ...ContactData
          ...EducationData
        }
      }
    }
  `);

  const contact = allDataYaml.nodes.reduce((result, curr) => {
    for (const prop in curr) {
      if (curr.hasOwnProperty(prop) && curr[prop]) {
        result[prop] = curr[prop];
      }
    }
    return result;
  }, {});

  const propertyRenderers = {
    name: (contact, index) => <h1 key={index}>{contact.name}</h1>,
    role: (contact, index) => <h2 key={index}>{contact.role}</h2>,
    url: (contact, index) => (
      <a key={index} className="link-container" href={`https://${contact.url}`}>
        <h4>{contact.url}</h4>
      </a>
    ),
    message: (contact, index) => (
      <div key={index} className="contact-message">
        <Content source={contact.message} />
      </div>
    ),
    contacts: (contact, index) =>
      contact.contacts && (
        <div key={index} className="contact-methods">
          {contact.contacts.map((entry, i) => (
            <div key={i} className="contact-entry">
              <a
                className="link-container"
                href={entry.url}
                target="_blank"
                rel="noreferrer"
              >
                <Icon icon={Icon[entry.name.toUpperCase()]} />
                <h4>{entry.content}</h4>
              </a>
            </div>
          ))}
        </div>
      ),
    schools: (contact, index) =>
      contact.schools && (
        <div key={index} className="contact-schools">
          {contact.schools.map((entry, i) => (
            <div key={i} className="contact-school">
              <em>{entry.dates}</em>
              <h2 className="contact-degree">{entry.degree} {entry.majors.join(', ')}</h2>
              <h3>
                {entry.name} <small>{entry.location}</small>
              </h3>
            </div>
          ))}
        </div>
      ),
  };

  return (
    <section className="contact">
      {properties.map((property, i) => propertyRenderers[property](contact, i))}
    </section>
  );
};

export default Contact;

export const queryContact = graphql`
  fragment ContactData on DataYaml {
    name
    role
    url
    message
    contacts {
      name
      content
      url
      icon
    }
  }
`;

export const queryEducation = graphql`
  fragment EducationData on DataYaml {
    schools {
      name
      degree
      dates
      location
      majors
      url
    }
    courses {
      name
      dates
      title
      url
    }
  }
`;
