import React from 'react';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import Layout from '../components/layout';
import Contact from '../components/contact';

const shortcodes = { Link, Contact };

export const InfoPageTemplate = ({
  _title,
  _subtitle,
  _featuredImage,
  body,
}) => {
  return (
    <section className="section">
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{body}</MDXRenderer>
      </MDXProvider>
    </section>
  );
};

const InfoPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
    slug={page.frontmatter.slug || false}
  >
    <InfoPageTemplate {...page} {...page.frontmatter} />
  </Layout>
);

export default InfoPage;

export const pageQuery = graphql`
  query InfoPage($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        slug
        template
        featuredImage
        meta {
          title
          description
        }
      }
    }
  }
`;
